<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-user"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="user && !spinners.loading">
          : <h3 class="d-inline"><CBadge color="secondary">{{ user.first_name }}</CBadge></h3>
        </template>
      </h5>

      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'Couriers' }">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <form-summary
        v-if="!isValid"
        class="form-errors alert alert-danger"
        :validator="$v.form"
        :attributes="attributes"
      />

      <CForm class="courier-form" v-if="!(spinners.loading)">
        <CTabs class="tab-menu" :active-tab.sync="activeTab" variant="pills" :vertical="{ navs: 'col-xl-2 col-md-3', content: 'col-xl-10 col-md-9' }">
          <CTab active>
            <template slot="title">
              <CIcon name="cil-task"/> General Information
            </template>

            <div class="d-flex flex-column">
              <div v-if="!itemId" class="mb-3 d-flex flex-column flex-md-row">
                <v-select
                  class="w-100"
                  :placeholder="users.length == 0 ? 'Search for a user..' : 'Select or search..'"
                  v-model="user"
                  :options="users"
                  :searchable="true"
                  :loading="spinners.searching && users.length == 0"
                  @search="searchUsers"
                  @input="handleUserInput"
                >
                  <template v-slot:option="user">
                    <div class="d-flex align-items-center">
                      <div class="c-avatar mr-2">
                        <img :src="user.image_icon || user.gravatar" class="c-avatar-img">
                      </div>
                      <span class="small"><strong>{{ user.first_name }}</strong> #{{ user.id }}  ({{ user.email }})</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="user">
                    {{ user.first_name }} #{{ user.id }}
                  </template>
                  <span slot="no-options">Type to search users..</span>
                </v-select>
              </div>

              <template v-if="user">
                <!-- User Info -->
                <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between mb-4 h-100">
                  <div class="flex-fill align-self-center">
                    <CImg
                      :src="user.image_icon || user.gravatar"
                      class="rounded-circle bg-white"
                      style="width: 96px; height: 96px"
                      placeholderColor="lightgray"
                    />
                    <h2 class="d-inline ml-2">{{ user.first_name }}</h2>
                  </div>

                  <div class="p-2 mr-4 user-info">
                    <address class="mb-1 blockquote">
                      <strong>#{{ user.id }}</strong><br>
                      <CIcon name="cil-mobile" /> {{ form.detail.phone_number ?? user.mobile }}
                    </address>
                    <address class="mb-1 blockquote">
                      <CIcon name="cil-at" /> <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                    </address>

                    <div class="button">
                      <CLink :to="{ name: 'Edit User', params: { id: user.id }, query: { tab: 0 } }">
                        <CIcon name="cil-pen" /> Edit User
                      </CLink>
                    </div>
                  </div>
                </div>

                <!-- Business Info -->
                <h5 class="pb-1" style="border-bottom: 2px solid #636f83;">Business Information</h5>

                <!-- Hubs -->
                <div class="form-group w-100 mb-2">
                  <label class="d-block">Hubs:</label>
                  <v-select
                    placeholder="Hubs.."
                    label="name"
                    v-model="form.hubs"
                    :options="hubs"
                    :searchable="false"
                    :reduce="hub => hub.id"
                    multiple
                  >
                    <template v-slot:option="hub">
                      <strong>{{ hub.zip }}</strong> -
                      <span>{{ hub.name }} </span>
                    </template>
                  </v-select>

                </div>

                <!-- Vehicle Info -->
                <div class="d-flex flex-column flex-md-row mb-2">
                  <CSelect
                    class="w-100 mr-2"
                    label="Travel Mode:"
                    placeholder="Please select.."
                    :options="$globalVar.travelMode"
                    :value.sync="$v.form.vehicle.mode.$model"
                  />

                  <CInput
                    class="w-100"
                    label="Vehicle Plate:"
                    placeholder="License Plate"
                    :value.sync="$v.form.vehicle.license_plate.$model"
                    @input="val => { if (val.trim() === '') form.vehicle.license_plate = null }"
                  />
                </div>

                <!-- Contract Model -->
                <div class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CSelect
                    class="w-100"
                    label="Contract Model:"
                    placeholder="Please select.."
                    :options="[
                      { value: 'per_order', label: 'Per order' },
                      { value: 'hourly', label: 'Hourly' }
                    ]"
                    :value.sync="$v.form.detail.contract_model.$model"
                  />

                  <CInput
                    v-if="form.detail.contract_model === 'per_order'"
                    class="w-100"
                    label="Fee per Order:"
                    placeholder="0.00"
                    :value.sync="$v.form.detail.per_order_fee.$model"
                    type="number"
                    step="0.01"
                    pattern="^\d+(?:\.\d{1,2})?$"
                  >
                    <template #prepend-content><CIcon name="cil-euro"/></template>
                  </CInput>

                  <CInput
                    v-else-if="form.detail.contract_model === 'hourly'"
                    class="w-100"
                    label="Hourly Rate:"
                    placeholder="0.00"
                    :value.sync="$v.form.detail.hourly_rate.$model"
                    type="number"
                    step="0.01"
                    pattern="^\d+(?:\.\d{1,2})?$"
                  >
                    <template #prepend-content><CIcon name="cil-euro"/></template>
                  </CInput>
                </div>

                <div v-if="form.detail.contract_model === 'per_order'" class="d-flex flex-column flex-md-row flex-gap-2 mb-4">
                  <CInput
                    class="w-100"
                    label="VAT (BTW) Number:"
                    placeholder="VAT number, if any"
                    :value.sync="$v.form.detail.vat_number.$model"
                    @focusout="fixVat"
                    :isValid="checkIfValid('detail.vat_number')"
                    @input="value => setNullIfEmpty(value, 'detail.vat_number')"
                  >
                    <template #append>
                      <CButton
                        color="info"
                        :disabled="spinners.btnCompanyInfo || !form.detail.vat_number || !isValidVat(form.detail.vat_number)"
                        @click="getCompanyInfo"
                      >
                        <CSpinner size="sm" v-if="spinners.btnCompanyInfo" />
                        Get Company Info
                      </CButton>
                    </template>
                  </CInput>

                  <CInput
                    class="w-100"
                    label="Company Name:"
                    placeholder="Company name"
                    :value.sync="$v.form.detail.company_name.$model"
                  />
                </div>


                <!-- Personal Info -->
                <h5 class="pb-1" style="border-bottom: 2px solid #636f83;">Personal Information</h5>

                <div class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CSelect
                    class="w-100"
                    label="Country:"
                    placeholder="Please select.."
                    :options="authCountries"
                    :value.sync="$v.form.detail.country_id.$model"
                  />
                  <CInput
                    class="w-100"
                    label="National Registration Number:"
                    placeholder="Rijksregisternummer"
                    :value.sync="$v.form.detail.reg_number.$model"
                    @input="value => setNullIfEmpty(value, 'detail.reg_number')"
                  />
                </div>

                <div class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CInput
                    type="date"
                    class="w-100"
                    label="Birth Date:"
                    :value.sync="$v.form.detail.birth_date.$model"
                    :max="new Date().toISOString().slice(0,10)"
                  />
                  <CInput
                    class="w-100"
                    label="Phone Number:"
                    placeholder="Phone number"
                    :lazy="false"
                    :value.sync="$v.form.detail.phone_number.$model"
                  />
                </div>

                <section class="address">
                  <CInput
                    id="verify_address"
                    :value.sync="verified_address"
                    class="w-100"
                    label="Verify Address From Google:"
                    placeholder="Enter a location"
                    :lazy="false"
                  >
                    <template #prepend>
                      <CButton color="info"><CIcon name="cil-location-pin"/></CButton>
                    </template>
                  </CInput>

                  <div class="d-flex flex-column flex-md-row">
                    <CTextarea
                      class="w-100"
                      rows="2"
                      label="Address:"
                      placeholder="Street name and house number"
                      :lazy="false"
                      :value.sync="$v.form.detail.address.$model"
                    />
                  </div>

                  <div class="d-flex flex-column flex-md-row flex-gap-2">
                    <CInput
                      class="w-100"
                      label="Postal Code:"
                      placeholder="Postal code"
                      :value.sync="$v.form.detail.postal_code.$model"
                    />
                    <CInput
                      class="w-100"
                      label="City:"
                      placeholder="City"
                      :value.sync="$v.form.detail.city.$model"
                    />
                  </div>
                </section>
              </template>
            </div>
          </CTab>

          <CTab :disabled="!form.user_id">
            <template slot="title">
              <div><CIcon name="cil-lock-unlocked"/> Permissions</div>
            </template>
            <h3>Permissions</h3>
            <courier-permissions :permissions.sync="form.detail.permissions" />
          </CTab>

          <CTab :disabled="!form.user_id">
            <template slot="title">
              <div><CIcon name="cil-clock"/> Working Hours</div>
            </template>
            <h3>Working Hours</h3>
            <working-hours
              :workingHours.sync="workingHours"
              @validate="(invalid) => workingHoursInvalid = invalid"
            />
          </CTab>

          <CTab :disabled="!form.user_id">
            <template slot="title">
              <div><CIcon name="cil-mood-good"/> Custom Working Hours</div>
            </template>
            <h3>Custom Working Hours</h3>
            <custom-working-hours
              :customWorkingHours.sync="customWorkingHours"
              @validate="(invalid) => customWorkingHoursInvalid = invalid"
            />
          </CTab>
        </CTabs>
      </CForm>

      <CSpinner v-else color="primary" />
    </CCardBody>

    <CCardFooter class="sticky-bottom">
      <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
        <CButton
          color="primary"
          :disabled="spinners.loading || spinners.btnSubmit || !form.user_id"
          @click="submit()"
        >
          <CSpinner v-if="spinners.btnSubmit" size="sm" /> Submit
        </CButton>
      </div>
    </CCardFooter>

    <mc-spinner :opacity="0.8" v-show="spinners.btnSubmit" />
  </CCard>

</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate"
import { helpers, required, requiredIf } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';
import CourierPermissions from "@/components/Permissions/Courier.vue";
import WorkingHours from './WorkingHours'
import CustomWorkingHours from './CustomWorkingHours'

const hasHouseNumber = (address) => {
  var regex = /\d+/g;
  return regex.test(address);
}

export default {
  name: 'Courier',
  components: {
    'courier-permissions': CourierPermissions,
    'working-hours': WorkingHours,
    'custom-working-hours': CustomWorkingHours
  },

  data() {
    return {
      activeTab: 0,
      isEdit: false,
      form: {
        user_id: null,
        hubs: [],
        vehicle: {
          mode: 'TWO_WHEELER',
          license_plate: null,
        },
        working_hours: [],
        detail: {
          reg_number: null,
          birth_date: null,
          phone_number: null,
          address: null,
          city: null,
          postal_code: null,
          country_id: null,

          vat_number: null,
          company_name: null,

          parent_id: null,
          is_dispatcher: null,

          contract_model: 'per_order',
          per_order_fee: null,
          hourly_rate: null,

          permissions: []
        },
      },

      workingHours: [],
      customWorkingHours: [],
      workingHoursInvalid: false,
      customWorkingHoursInvalid: false,

      user: null,
      users: [],
      hubs: [],

      spinners: {
        loading: false,
        searching: false,
        btnSubmit: false,
        btnCompanyInfo: false,
      },
      unsavedChanges: false,

      // Google Maps API Autocomplete
      verified_address: '',
      autocomplete_address: null,

      // Vuelidate-error-extractor
      attributes: {
        user_id: 'User',
        detail: {
          reg_number: 'National registration number',
          birth_date: 'Birth date',
          phone_number: 'Phone number',
          address: 'Address',
          city: 'City',
          postal_code: 'Postal code',
          country_id: 'Country',

          vat_number: 'VAT (BTW) number',
          company_name: 'Company name',

          contract_model: 'Contract model',
          per_order_fee: 'Fee per order',
          hourly_rate: 'Hourly rate',
        }
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      user_id: { required },
      hubs: { },
      vehicle: {
        mode: { required },
        license_plate: { },
      },
      detail: {
        reg_number: { required },
        birth_date: { required },
        phone_number: { required },
        address: { required, hasHouseNumber },
        city: { required },
        postal_code: { required },
        country_id: { required },

        vat_number: {
          required: requiredIf(function (value) {
            return !!value.vat_number && this.form.detail.contract_model === 'per_order';
          }),
          checkVAT: function (value) {
            return this.isValidVat(value);
          },
        },
        company_name: {
          required: requiredIf(function () {
            return this.form.detail.contract_model === 'per_order';
          }),
        },

        parent_id: { },
        is_dispatcher: { },

        contract_model: { required },
        per_order_fee: {
          required: requiredIf(function () {
            return this.form.detail.contract_model === 'per_order';
          }),
        },
        hourly_rate: {
          required: requiredIf(function () {
            return this.form.detail.contract_model === 'hourly';
          }),
        },
      }
    }
  },

  // Route koruması için
  beforeRouteLeave (to, from , next) {
    if (this.unsavedChanges === true) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      this.unsavedChanges = !answer;
      next(answer) // answer: true or false
    } else {
      next()
    }
  },

  async beforeMount() {
    this.searchUsers = _debounce(async (val) => {
      await this.getUsers(val);
    }, 500);

    await this.getHubs();
  },

  async mounted() {
    this.activeTab = this.$route.query.tab ? parseInt(this.$route.query.tab) : 0;

    // F5 veya başka bir siteye gitmeden önce 'Yaptığınız değişiklikler kaydedilmemiş olabilir.' koruması için
    window.onbeforeunload = () => this.unsavedChanges === true ? true : null;

    this.$watch('form', { handler: 'watchForm', deep: true });
    this.$watch('workingHours', { handler: 'watchForm', deep: true });
    this.$watch('customWorkingHours', { handler: 'watchForm', deep: true });

    if (this.itemId) {
      await this.getForm(this.itemId);
      this.initAutocomplete();
    } else {
      this.form.detail.permissions = this.$globalVar.courierPermissions.filter(p => p.default).pluck('name');
    }

    this.$watch('form.detail.country_id', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.autocomplete_address.setComponentRestrictions({
          country: [this.country?.iso2]
        });
      }
    });

    setTimeout(() => {
      sessionStorage.setItem(`courier-form`, JSON.stringify(this.transformedForm));
      this.unsavedChanges = false;
    }, 500);
  },

  computed: {
    ...mapGetters(["errors"]),
    isValid() { return !(this.$v.form.$invalid)},
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return Number(this.$route.params.id) || null },
    transformedForm() {
      const { hubs, working_hours, detail, ...rest } = this.form;

      const permissionsMap = this.$globalVar.courierPermissions.reduce((acc, { name }) => {
        acc[name] = detail.permissions.includes(name);
        return acc;
      }, {});

      return {
        ...rest,
        hubs: this.form.hubs.join(','),
        working_hours: [...this.workingHours, ...this.customWorkingHours],
        detail: {
          ...detail,
          ...permissionsMap,
          permissions: undefined // Remove the permission property
        }
      }
    },
    country() {
      return this.authCountries.find((item) => item.value == (this.form.detail.country_id || this.$store.state.countryId));
    },
  },

  methods: {
    checkIfValid (fieldName) {
      const fields = fieldName.split(".");
      const field = fields.length > 1
        ? this.$v.form[fields[0]][fields[1]]
        : this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    watchForm(newV, oldV) {
      const initialState = sessionStorage.getItem(`courier-form`);
      const newState = JSON.stringify(this.transformedForm);
      this.unsavedChanges = initialState !== newState;
    },

    async getUsers(val) {
      if (val.length < 3) {
        return;
      }

      this.spinners.searching = true;
      await this.$axios.get(this.$backend.USERS.GET_ALL, {
        params: {
          search: val,
          'get-relations': false,
          'except-couriers': true,
        }
      })
        .then(({ data }) => {
          this.users = data.data.map((el) => {
            return { ...el, label: `i#${el.id} ${el.first_name} ${el.email}` };
          });
          this.spinners.searching = false;
        });
    },

    handleUserInput(user) {
      if (user === null) {
        this.form.user_id = null;
        this.form.detail.phone_number = null;
        this.form.detail.address = null;
        this.form.detail.city = null;
        this.form.detail.postal_code = null;
        this.form.detail.country_id = null;
        return;
      }

      this.form.user_id = user.id;
      this.form.detail.phone_number = user.mobile;
      this.form.detail.address = user.address;
      this.form.detail.city = user.city;
      this.form.detail.postal_code = user.postal_code;

      this.$nextTick(() => {
        this.initAutocomplete();
        this.form.detail.country_id = this.country.value;
      });
    },

    async getForm() {
      this.isEdit = true
      this.spinners.loading = true;

      await this.$axios.get(this.$backend.COURIER.COURIERS.SHOW.replace("{id}", this.itemId))
        .then(({data}) => {
          this.user = data;

          this.form = {
            user_id: data.id,
            hubs: data.hubs?.pluck('id'),
            vehicle: {
              mode: data.vehicle?.mode,
              license_plate: data.vehicle?.license_plate,
            },
            working_hours: data.working_hours,
            detail: {
              reg_number: data.detail.reg_number ?? '-',
              birth_date: data.detail.birth_date ?? '2000-01-01',
              phone_number: data.detail.phone_number ?? this.user.mobile,
              address: data.detail.address ?? this.user.address,
              city: data.detail.city ?? this.user.city,
              postal_code: data.detail.postal_code ?? this.user.postal_code,
              country_id: data.detail.country_id ?? this.country.value,

              vat_number: data.detail.vat_number,
              company_name: data.detail.company_name ?? '-',

              parent_id: data.detail.parent_id,
              is_dispatcher: data.detail.is_dispatcher,

              contract_model: data.detail.contract_model,
              per_order_fee: data.detail.per_order_fee ?? 0,
              hourly_rate: data.detail.hourly_rate,

              permissions: this.$globalVar.courierPermissions.filter(p => data.detail[p.name]).pluck('name')
            },
          }

          this.workingHours = data.working_hours.filter((wh) => wh.day_of_week != null && !wh.custom_date);
          this.customWorkingHours = data.working_hours
            .filter((wh) => wh.day_of_week == null && wh.custom_date)
            .map(el => ({ ...el, invalid: false }));

          this.spinners.loading = false;
        })
        .catch((error) => {
          this.$toast.error(`${error.response.data.message}`);
          setTimeout(() => { this.$router.push({ name: 'Couriers' }) }, 3000);
        })
        .finally(() => { this.unsavedChanges = false })
    },

    async getHubs() {
      await this.$axios
        .get(this.$backend.COURIER.HUBS.GET_LIST)
        .then(({ data }) => this.hubs = data)
        .catch(() => this.hubs = [])
    },

    async submit() {
      window.scrollTo(0, 0);

      if (!this.isValid) {
        this.validate();
        this.activeTab = 0;
        return;
      }

      if (this.workingHoursInvalid) {
        this.activeTab = 2;
        this.$toast.error('Check the working hours!')
        return;
      }

      if (this.customWorkingHoursInvalid) {
        this.activeTab = 3;
        this.$toast.error('Check the custom working hours!')
        return;
      }

      if (this.isEdit) {
        await this.update()
      } else {
        await this.store()
      }
    },

    async update() {
      this.spinners.btnSubmit = true

      await this.$axios.put(this.$backend.COURIER.COURIERS.UPDATE.replace("{id}", this.itemId), this.transformedForm)
        .then(({ data }) => {
          this.$toast.success(`${data.data.message}`);
          this.customWorkingHours = this.customWorkingHours.filter((wh) => !wh.deleted);

          sessionStorage.setItem(`courier-form`, JSON.stringify(this.transformedForm));
          this.unsavedChanges = false;
        })
        .catch((error) => {
          this.spinners.btnSubmit = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.spinners.btnSubmit = false
        });
    },

    async store() {
      this.spinners.btnSubmit = true

      await this.$axios.post(this.$backend.COURIER.COURIERS.STORE, this.transformedForm)
        .then(({ data }) => {
          this.$toast.success(`${data.data.message}`);
          this.unsavedChanges = false

          setTimeout(() => {
            this.$router.push({ name: 'Couriers' })
          }, 2000)
        })
        .catch((error) => {
          this.spinners.btnSubmit = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.spinners.btnSubmit = false
        });
    },

    validate() {
      this.$v.$touch()
    },

    setNullIfEmpty(value, path) {
      if (value.trim() === '') {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const obj = keys.reduce((acc, key) => acc[key], this.form);
        obj[lastKey] = null;
      }
    },

    // VAT Number
    fixVat(event) {
      const fixedValue = this.$globalFunc.fixVAT(event.target.value);
      event.target.value = fixedValue;
    },
    isValidVat(vatNumber) {
      const result = this.$globalFunc.checkVAT(vatNumber, this.country.value);
      return result || !helpers.req(vatNumber) || false;
    },
    async getCompanyInfo() {
      this.spinners.btnCompanyInfo = true

      try {
        const { data } = await this.$axios.post(this.$backend.RESTAURANT.VAT_VALIDATION, {
          country_code: this.country?.iso2.toUpperCase(),
          vat_number: this.form.detail.vat_number,
        });

        if (data.response) {
          const name = data.response.name.replace(/\u00a0/g, ' ');
          const address = data.response.address.replace(/\u00a0/g, ' ').trim();

          const [firmType, ...firmName] = name.includes('|')
            ? name.split('|').map(part => part.trim())
            : name.split(' ');

          // this.form.detail.company_type = firmType;
          this.form.detail.company_name = firmName.join(" ");

          // address: "Zeven Septemberlaan 7\n3550 Heusden-Zolder"
          const [firmAddress, postcodeAndCity] = address.split('\n');
          const [firmPostcode, ...firmCity] = postcodeAndCity.split(' ');

          this.form.detail.address = firmAddress;
          this.form.detail.postal_code = firmPostcode;
          this.form.detail.city = firmCity.join(" ");

          this.$toast.success(`Valid VAT number`);
        } else {
          this.$toast.error(`Invalid VAT (BTW) number!`);
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      } finally {
        this.spinners.btnCompanyInfo = false;
      }
    },

    // Google places
    initAutocomplete() {
      // Google Maps API Autocomplete: Place Autocomplete Address Form
      this.autocomplete_address = new google.maps.places.Autocomplete(
        document.getElementById("verify_address"),
        {
          componentRestrictions: { country: [this.country?.iso2] },
          fields: ["address_components", "geometry", "formatted_address"],
          types: ["address"],
        }
      );
      this.autocomplete_address.addListener("place_changed", this.setPlace);
    },
    setPlace() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete_address.getPlace();

      let street_number = "";
      let route = "";
      let postcode = "";
      let locality = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street_number = component.long_name;
            break;
          }

          case "route": {
            route = component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            locality = component.long_name;
            break;
          }
        }
      }

      this.verified_address = place.formatted_address;

      this.form.detail.address = `${route} ${street_number}`;
      this.form.detail.postal_code = postcode.replace(/\D/g, "");
      this.form.detail.city = locality;
    },
  }
}
</script>

<style>
.tab-menu > div:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  /* border-bottom: 1px solid #d8dbe0; */
}

form.courier-form .form-control,
form.courier-form option,
form.courier-form .vs__search,
form.courier-form .vs__dropdown-menu,
form.courier-form .vs__selected {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}
form.courier-form .vs__dropdown-toggle {
  padding: 2px 0 6px;
}
form.courier-form label.c-switch {
  margin-top: 0.3rem;
}

.add-couriers-modal .modal-header {
  padding: 0.75rem !important;
}
.add-couriers-modal .modal-footer {
  padding: 0.25rem !important;
}
</style>

<style scoped>
.user-info {
  position: relative;
}
.user-info .button {
  opacity: 0;
  transition: opacity .35s ease;
  background: rgba(0, 0, 0, .3);
  text-align: center;
  position: absolute;
  width: 100%;
  border-radius: 25px;
}
.user-info .button a {
  text-align: center;
  color: white;
  z-index: 1;
}
.user-info:hover .button {
  opacity: 1;
}

section.address {
  background: #F8F0DF;
  padding: 1rem;
  border-radius: 0.5rem;
}
</style>
